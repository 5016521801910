<template>
  <div>
    <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Tous les Prises
    </h2>
    <calendar
      class="custom-calendar max-w-full"
      :masks="prisesCalendarMasks"
      :attributes="prisesCalendar"
      disable-page-swipe
      is-expanded
    >
      <template v-slot:day-content="{ day, attributes }">
        <div class="flex flex-col h-full z-10 overflow-hidden">
          <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
          <div class="flex-grow overflow-y-auto overflow-x-auto">
            <p
              v-for="attr in attributes"
              :key="attr.key"
              class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
              :class="attr.customData.class"
            >
              {{ attr.customData.title }}
            </p>
          </div>
        </div>
      </template>
    </calendar>

    <div
      v-if="recentPrises"
      class="w-full mb-8 overflow-hidden rounded-lg shadow-xs"
    >
      <div class="w-full overflow-x-auto">
        <table class="w-full whitespace-no-wrap">
          <thead>
            <tr
              class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
            >
              <th class="px-4 py-3">Titre</th>
              <th class="px-4 py-3">Participations validées</th>
              <th class="px-4 py-3">Participations en attente</th>
              <th class="px-4 py-3">Début</th>
              <th class="px-4 py-3">Fin</th>
              <th class="px-4 py-3">Quota</th>
            </tr>
          </thead>
          <tbody
            class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
          >
            <tr
              v-for="(prise, index) in recentPrises"
              :key="index"
              class="text-gray-700 dark:text-gray-400"
            >
              <router-link
                :to="`/prise/${prise.id}`"
                class="px-4 py-3 text-sm td-router-link"
              >
                {{ prise.title }}
              </router-link>
              <td class="px-4 py-3 text-sm">
                {{ prise.participants_count }}
              </td>
              <td class="px-4 py-3 text-sm">
                {{ prise.participants_waiting_validation_count }}
              </td>
              <td class="px-4 py-3 text-sm">
                {{ formatDate(prise.start_at) }}
              </td>
              <td class="px-4 py-3 text-sm">
                {{ formatDate(prise.end_at) }}
              </td>
              <td class="px-4 py-3 text-sm">
                {{ prise.quota }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800"
      >
        <!-- Pagination -->
        <span class="flex col-span-4 mt-2 sm:mt-auto sm:justify-end">
          <nav aria-label="Table navigation">
            <ul class="inline-flex items-center">
              <li>
                <button
                  class="px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple"
                  aria-label="Previous"
                  @click="changePagination(true)"
                >
                  <svg
                    aria-hidden="true"
                    class="w-4 h-4 fill-current"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </li>
              <li class="px-3 py-1 rounded-md">
                {{ recentPrisesPage + 1 }}
              </li>
              <li>
                <button
                  class="px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-purple"
                  aria-label="Next"
                  @click="changePagination(false)"
                >
                  <svg
                    class="w-4 h-4 fill-current"
                    aria-hidden="true"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </li>
            </ul>
          </nav>
        </span>
      </div>
    </div>

    <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Créer un nouveau prise
    </h2>
    <div
      class="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800"
      :onsubmit="postNewPrise"
    >
      <label class="block text-sm">
        <span class="text-gray-700 dark:text-gray-400">Titre</span>
        <input
          class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
          placeholder="Exemple: Entrer dans une démarche professionnelle"
          v-model="newPrise.title"
          type="text"
        />
      </label>
      <label class="block mt-4 text-sm">
        <span class="text-gray-700 dark:text-gray-400">Introduction</span>
        <textarea
          class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
          placeholder="Présentation en quelques mots du prise"
          v-model="newPrise.intro"
        />
      </label>
      <label class="block mt-4 text-sm">
        <span class="text-gray-700 dark:text-gray-400">Programme</span>
        <textarea
          class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
          placeholder="Description detaillé du programme de la journée"
          v-model="newPrise.program"
        />
      </label>
      <label class="block mt-4 text-sm">
        <span class="text-gray-700 dark:text-gray-400">Description</span>
        <textarea
          class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
          placeholder="Présenter en quoi consiste le prise"
          v-model="newPrise.description"
        />
      </label>
      <label class="block mt-4 text-sm">
        <span class="text-gray-700 dark:text-gray-400">
          URL de la vidéo YouTube (optionnel)
        </span>
        <input
          class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
          placeholder="https://www.youtube.com/..."
          v-model="newPrise.video_url"
          type="url"
        />
      </label>
      <label class="block mt-4 text-sm">
        <span class="text-gray-700 dark:text-gray-400">Date de début</span>
      </label>
      <datetime
        type="datetime"
        v-model="newPrise.start_at"
      ></datetime>
      <label class="block mt-4 text-sm">
        <span class="text-gray-700 dark:text-gray-400">Date de fin</span>
      </label>
      <datetime type="datetime" v-model="newPrise.end_at"></datetime>
      <label class="block mt-4 text-sm">
        <span class="text-gray-700 dark:text-gray-400">
          Quota de participants
        </span>
        <input
          class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
          placeholder="25"
          v-model.number="newPrise.quota"
          type="number"
          min="0"
        />
      </label>
      <label class="block mt-4 text-sm">
        <span class="text-gray-700 dark:text-gray-400">
          Détails privés
        </span>
        <input
          class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
          placeholder="03 Avenue Leclerc, Paris 75016"
          v-model="newPrise.private_details"
          type="text"
        />
      </label>
      <button
        class="mt-4 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
        @click="postNewPrise"
      >
        Poster
      </button>
    </div>
    <Banner :data="errorBannerState" />
  </div>
</template>

<script>
import { postNew, recentPrises } from "@/utils/api/prises";
import { format } from "@/utils/date";
import Banner from "@/components/Banner";

export default {
  components: { Banner },
  data() {
    return {
      recentPrises: null,
      recentPrisesPage: 0,
      prisesCalendarMasks: {
        weekdays: "WWW"
      },
      prisesCalendar: [],
      newPrise: {
        title: null,
        intro: null,
        start_at: null,
        end_at: null,
        program: null,
        description: null,
        video_url: null,
        private_details: null,
        quota: null
      },
      errorBannerState: {
        open: false,
        error: "Le prise ne peut pas etre posté."
      }
    };
  },
  mounted() {
    this.fetchPrises();
  },
  methods: {
    formatDate: format,
    fetchPrises() {
      // retrieve prises from api
      recentPrises(this.$data.recentPrisesPage).then(res => {
        this.$data.recentPrises = res.data;

        // process for calendar view
        this.$data.recentPrises.forEach((prise, index) => {
          this.$data.prisesCalendar.push({
            key: index,
            customData: {
              title: prise.title,
              class: "bg-blue-500 text-white"
            },
            dates: {
              start: prise.start_at,
              end: prise.end_at
            }
          });
        });
      });
    },
    changePagination(isLess) {
      // update page counter and call api
      if (isLess && this.$data.recentPrisesPage !== 0) {
        this.$data.recentPrisesPage--;
        this.fetchPrises();
      } else if (!isLess && this.$data.recentPrisesPage.length > 99) {
        this.$data.recentPrisesPage++;
        this.fetchPrises();
      }
    },
    postNewPrise(e) {
      e.preventDefault();

      // verify empty values
      if (/^\s*$/.test(this.$data.newPrise.video_url)) {
        this.$data.newPrise.video_url = null;
      }

      postNew(
        this.$data.newPrise.title,
        this.$data.newPrise.intro,
        this.$data.newPrise.start_at,
        this.$data.newPrise.end_at,
        this.$data.newPrise.program,
        this.$data.newPrise.description,
        this.$data.newPrise.video_url,
        this.$data.newPrise.private_details,
        this.$data.newPrise.quota
      )
        .then(res => {
          // add to recent prises
          this.recentPrises
            ? this.$data.recentPrises.unshift(res.data)
            : (this.$data.recentPrises = [res.data]);

          // add to calendar
          this.$data.prisesCalendar.push({
            key: this.$data.prisesCalendar.length + 1,
            customData: {
              title: res.data.title,
              class: "bg-blue-500 text-white"
            },
            dates: {
              start: res.data.start_at,
              end: res.data.end_at
            }
          });

          // reset form
          this.$data.newPrise = {
            title: null,
            intro: null,
            start_at: null,
            end_at: null,
            program: null,
            description: null,
            video_url: null,
            private_details: null,
            quota: null
          };
        })
        .catch(err => {
          // display error to banner component
          this.$data.errorBannerState.error = err.response.data.data;
          this.$data.errorBannerState.open = true;
        });
    }
  }
};
</script>

<style lang="sass" scoped>
.flex.flex-col.h-full.z-10.overflow-hidden
  min-height: 60px
</style>
